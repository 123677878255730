
import { Vue, Options } from 'vue-class-component';
import axios, { AxiosRequestConfig, CancelToken, CancelTokenSource } from 'axios';
import debounce from 'lodash-es/debounce';
import { RouteLocationNormalized } from 'vue-router';
import {
  BaseTextInput,
  BaseButton,
  ButtonLink,
  BaseIcon,
  DataTable,
  SpecialityButton,
  BasePopover,
  BasePagination,
  PopoverButton,
  BaseModal,
  BaseChip,
  BaseSelect,
  BaseCard
} from '@/lib/components';
import { OrganisationUserService, OrganisationRoleAssignmentService, OrganisationRoleService } from '@/services/api';
import { PaginatedResponse, Role, RoleAssignment, User } from '@/models';
import { ISuggestion } from '@/lib';
import { useProgressStore } from '@/stores/progress.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    roleId: {
      type: String,
      required: true
    },
    organisationId: {
      type: String,
      required: true
    }
  },
  components: {
    BaseCard,
    BaseSelect,
    PopoverButton,
    BaseChip,
    BaseTextInput,
    BaseButton,
    ButtonLink,
    BaseIcon,
    DataTable,
    SpecialityButton,
    BasePopover,
    BasePagination,
    BaseModal
  }
})
export default class RoleUsersPage extends Vue {
  roleId!: number;
  organisationId!: string;
  progressStore = useProgressStore();
  notificationStore = useNotificationStore();

  loading = true;

  rows: User[] = [];
  perPage = 0;
  total = 0;
  search = '';
  filtered = false;

  name = '';
  roleAssignments: RoleAssignment[] = [];
  users: Array<User> = [];
  selectedUsers: Array<ISuggestion> = [];
  showUsersModal = false;
  requests: Array<CancelTokenSource> = [];

  get orgRoleService(): OrganisationRoleService {
    return new OrganisationRoleService(this.organisationId);
  }

  get roleAssignmentService(): OrganisationRoleAssignmentService {
    return new OrganisationRoleAssignmentService(this.organisationId);
  }

  get orgUserService(): OrganisationUserService {
    return new OrganisationUserService(this.organisationId);
  }

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || 'title';
  }

  get filteredUsers(): Array<ISuggestion> {
    return this.users
      .filter((user) => !this.rows.map((u) => u.id).includes(user.id))
      .map((user) => ({
        label: `${user.given_name} ${user.family_name}`,
        id: user.id
      }))
      .filter((user) => !this.selectedUsers.map((u) => u.id).includes(user.id));
  }

  get availableUsers() {
    return this.filteredUsers.map((user) => ({
      label: user.label,
      value: user.id
    }));
  }

  created() {
    this.progressStore.startProgress();
    this.search = String(this.$route.query.search || '');
    this.fetchData();
    const unWatchRoute = this.$watch('$route', async (from: RouteLocationNormalized, to: RouteLocationNormalized) => {
      if (from.name === to.name && from.query !== to.query) {
        await this.updateUsers();
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        unWatchRoute();
      }
    });
    if (!this.$route.meta?.isAdmin) {
      this.$watch('organisationId', () => {
        this.$router.push({
          name: 'settings-roles',
          params: {
            organisationId: this.organisationId
          }
        });
      });
    }
  }

  unmounted() {
    this.progressStore.removeProgress();
    if (this.requests.length) {
      this.requests.map((request) => request.cancel());
    }
  }

  removeUser(user: ISuggestion) {
    this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1);
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  async updateUsers() {
    try {
      const request = axios.CancelToken.source();
      this.requests.push(request);
      const response = await this.fetchRoleUsers(request.token);
      this.rows = response.data;
      this.perPage = response.meta.per_page;
      this.total = response.meta.total;
      this.filtered = this.search.length > 0;
      this.roleAssignments = await this.fetchRoleAssignments(request.token);
      this.requests.splice(this.requests.findIndex((req) => req.token === request.token));
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.user.fetch-error')
        });
      }
    }
  }

  async fetchData() {
    try {
      const request = axios.CancelToken.source();
      this.requests.push(request);
      const [role, roleUsers, assignments, users] = await Promise.all([
        this.fetchRole(request.token),
        this.fetchRoleUsers(request.token),
        this.fetchRoleAssignments(request.token),
        this.fetchUsers(request.token)
      ]);
      this.requests.splice(-1);
      this.rows = roleUsers.data;
      this.perPage = roleUsers.meta.per_page;
      this.total = roleUsers.meta.total;
      this.filtered = this.search.length > 0;
      this.name = role.name;
      this.roleAssignments = assignments;
      this.users = users;
      this.progressStore.finishProgress();
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.progressStore.setError();
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.error.fetch-data')
        });
      }
    } finally {
      this.loading = false;
    }
  }

  async fetchRoleUsers(cancelToken: CancelToken): Promise<PaginatedResponse<Array<User>>> {
    const requestConfig: AxiosRequestConfig = {
      params: {
        page: this.page,
        sort: this.sort,
        ...(this.search ? { search: this.search } : {})
      },
      cancelToken
    };
    return (await this.orgRoleService.getAssignments(this.roleId, requestConfig)) as PaginatedResponse<User[]>;
  }

  async fetchUsers(cancelToken: CancelToken): Promise<Array<User>> {
    return (
      await this.orgUserService.index({
        cancelToken
      })
    ).data;
  }

  async fetchRole(cancelToken: CancelToken): Promise<Role> {
    return await this.orgRoleService.fetch(this.roleId, {
      cancelToken
    });
  }

  async fetchRoleAssignments(cancelToken: CancelToken): Promise<Array<RoleAssignment>> {
    return (
      await this.roleAssignmentService.index({
        params: {
          'filter[role_id]': this.roleId
        },
        cancelToken
      })
    ).data;
  }

  editUser(userId: string) {
    this.$router.push(`users/${userId}`);
  }

  getRoleAssignment(userId: string) {
    return this.roleAssignments.find((a) => a.user_id === userId);
  }

  async createRoleAssignment() {
    try {
      // TODO: assign roles for each selected users
      const promises = this.selectedUsers.map((user) =>
        this.roleAssignmentService.create({
          user_id: user.id,
          role_id: this.roleId,
          organisational_unit_id: this.organisationId
        })
      );
      await Promise.all(promises);
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.role.assign-success')
      });
      this.updateUsers();
    } catch (error) {
      console.warn('Issue assigning role', error);
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.role.assign-error')
      });
    } finally {
      this.setUserAssignModalVisibility(false);
    }
  }

  async deleteRoleAssignment(userId: string) {
    try {
      const assignment = this.getRoleAssignment(userId);
      if (assignment) {
        await this.roleAssignmentService.delete(assignment.id);
      }
      const request = axios.CancelToken.source();
      this.requests.push(request);
      this.rows = (await this.fetchRoleUsers(request.token)).data;
      this.requests.splice(this.requests.findIndex((req) => req.token === request.token));
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.warn(err);
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.role.remove-error')
        });
      }
    }
  }

  async updateSearch() {
    if (this.search !== this.$route.query.search) {
      // Maintain sort order and only add search param when non-empty
      await this.$router.replace({
        path: this.$route.path,
        query: {
          ...(this.$route.query.sort ? { sort: this.$route.query.sort } : {}),
          ...(this.search ? { search: this.search } : {})
        }
      });
    }
  }

  get updateSearchDebounced() {
    return debounce(() => this.updateSearch(), 500);
  }

  setUserAssignModalVisibility(visible: boolean) {
    this.selectedUsers = [];
    this.showUsersModal = visible;
  }

  cancel() {
    this.$router.back();
  }

  updateSelectedUser(userId: string) {
    const findUser = this.filteredUsers.find((user) => user.id === userId);
    if (findUser) {
      this.selectedUsers.push(findUser);
    }
  }
}
